<template>
  <div class="content">
    <el-container>
      <el-aside width="300px">
        <div class="left">
          <div class="block">
            <el-image style="width: 80px;   height: 80px" class="left-header" :src="avater">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
            <span class="demonstration">{{name}}</span>
          </div>
        </div>
        <el-aside width="300px">
          <div class="left-list">
            <div class="left-lists">
              <!--纵向子级选项卡-->
              <!--<ul
                :class="currentId == index ? 'isShow' : 'hidden'"
                v-for="(item, index) in leftList"
                :key="index"
              >
                <li
                  :class="currentID2 == index ? 'active1' : ' '"
                  v-for="(itemc, index) in leftList"
                  :key="index"
                  @click="handleChild(index)"
                >
                  {{ itemc }}
                </li>
              </ul>-->
              <el-collapse v-model="activeNames">
							  <el-collapse-item  v-for="(item,index) in leftList" :key="index" :name="index">
							  	<template slot="title">
							      <div class="lefttitle">{{item.first_title}}</div>
							    </template>
							    <div v-for="val in item.second_title"   @click="handleChild(val.num)" :class="currentID2 == val.num ? 'lefttitle secondtitle active1' : 'lefttitle secondtitle '">
							    	<router-link :to="val.link">
							    			<div>{{val.title}}</div>
							    	</router-link>
							    </div>
							  </el-collapse-item>
							    <div @click="handleChild(10)" :class="currentID2 == 10 ? 'active1 firsttitle' : 'firsttitle'">
							    	<router-link to="/Periodical/history/?id=10">
							    		<div style="width: 100%;">历史刊发</div>
							    	</router-link>
							    	
							    </div>
  						</el-collapse>
            </div>
          </div>
        </el-aside>
      </el-aside>
      <el-container>
        <el-main>
          <div class="right">
            <router-view ></router-view>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import QKmessage from './Setting/QKmessage'
import {showsQKidentify} from "../../API/identify.js"
export default {
  components: {
    QKmessage
  },
  data () {
    return {
		avater: require('../../assets/image/ygtx.jpg'),
		name:'',
    	activeNames: [0],
      leftList: [
        {"first_title":"设置","second_title":[{"title":"期刊信息维护","num":1,"link":"/Periodical/QKmessage/?id=1"},{"title":"密码重置","num":2,"link":"/Periodical/changePassword/?id=2"},{"title":"开通加急申请","num":21,"link":"/Periodical/isUrgent/?id=21"},{"title":"绑定手机号","num":3,"link":"/Periodical/bindtel/?id=3"}]},
        {"first_title":"待审核稿件","second_title":[{"title":"初审稿件","num":4,"link":"/Periodical/firstcheck/?id=4"},{"title":"复审稿件","num":5,"link":"/Periodical/secondcheck/?id=5"}]},
        {"first_title":"已审核稿件","second_title":[{"title":"补录用稿通知","num":6,"link":"/Periodical/passed/?id=6"},{"title":"驳回稿件","num":7,"link":"/Periodical/rejected/?id=7"}]},
        {"first_title":"稿件结算","second_title":[{"title":"待结算稿件","num":8,"link":"/Periodical/unbalanced/?id=8"},{"title":"已结算稿件","num":9,"link":"/Periodical/balanced/?id=9"}]}
      ],
      currentId: 0,
      currentID2:1,
      lists: [ //准备的假数据
        { id: 0 },
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 },
        { id: 5 },
        { id: 6 },
        { id: 7 },
        { id: 8 },
      ]
    }
  },
  methods: {
    handleChild: function (index) {
      this.currentID2 = index
    },
    //弹窗
    receive: function (row) {
      //显示弹框
      this.dialog = true;
    },
	showavater(){
	showsQKidentify(localStorage.getItem('QKId')).then(res=>{
    console.log(localStorage.getItem('QKId'));
		console.log(res)
      //  alert(this.URL);
		if(res.data.data.headimg.length!=0){
			this.avater=this.URL+res.data.data.headimg;
		}
		this.name=res.data.data.loginname
		this.$root.$emit('next1') //调用父路由中监听的next方法,然后传两个参
	})	
	}
  },
  mounted(){
	if(this.$route.query.id){
	 this.currentID2 = this.$route.query.id
	if( this.currentID2<=3){
		this.activeNames=[0]
	}else if( this.currentID2<=5){
		this.activeNames=[1]
	}else if( this.currentID2<=7){
		this.activeNames=[2]
	}else if( this.currentID2<=9){
		this.activeNames=[3]
	}else{
		this.activeNames=""
	}
	}
	this.showavater()
	 let _this = this //很重要把父路由的vue实例赋给_this
	        _this.$root.$on('next', function () {  //监听next事件，这里$root很重要，不懂去官网介绍
	            _this.showavater() //调用父路由中的方法
	        })

	
  }
}
</script>

<style  scoped>
.content {
  width: 80%;
  margin: 20px auto;
}
.left {
  width: 300px;
  height: 192px;
  background-color: #ffffff;
  border-radius: 5px;
}
.lefttitle{
	padding-left:30px;
	box-sizing: border-box;
}
.firsttitle{
	height: 50px;
	line-height: 50px;
	padding-left: 30px;
	font-size: 12px;
}
.secondtitle{
	height: 40px;
	line-height: 40px;
	color: #999;
}
.left-header {
  border-radius: 50%;
  margin: 40px 110px 0 110px;
}
.left-name {
  margin-bottom: 50px;
  text-align: center;
  position: relative;
}

.demonstration {
  width: 300px;
  height: 40px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #141414;
  line-height: 40px;
  display: block;

  text-align: center;
}
.left-list {
  width: 300px;
  margin-top: 20px;
  border-radius: 5px;
  background: #ffffff;
  cursor: pointer;
}

.left-list-wenzi {
  width: 300px;
  height: 50px;
  line-height: 50px;
  margin-left: 20px;
 
}
.active1 {
  border-left: 2px solid #ff4318;
  background-color: rgba(0, 0, 0, 0.1);
}

.tabcont {
  padding: 20px;
  position: absolute;
  left: 220px;
  top: 65px;
  right: 0;
  z-index: 100;
}
.tabcontent {
  display: none;
}

.left-lists ul {
  list-style: none;
  overflow-y: auto;
}
.left-lists ul li {
  height: 50px;
  line-height: 50px;
  text-align: left;
  padding-left: 20px;
  color: #333333;
}
.left-lists ul li span {
  display: block;
}
.isShow {
  display: block;
}
.hidden {
  display: none;
}
.el-main {
  padding: 0px 0px 20px 20px;
}
.right {
  width: 98%;
  padding: 10px 0px 0px 10px;
  background: #ffffff;
  margin-bottom: 20px;
}
</style>
