//security.js
import request from '@/utils/request'
import qs from 'qs'

// 查询个人资料（员工和服务商）
export function showsidentify (id,state) {
  let data = {
  id,state
  }
  data = qs.stringify(data)
  return request({
    url: '/facilitator/Showfws',
    method: 'post',
    data
  })
}
//查询期刊信息
export function showsQKidentify (id) {
  let data = {
  id
  }
  data = qs.stringify(data)
  return request({
    url: '/qikan/QueryQiKan',
    method: 'post',
    data
  })
}
//查询作者信息
export function showsZZidentify (id) {
  let data = {
  id
  }
  data = qs.stringify(data)
  return request({
    url: '/author/showauthor',
    method: 'post',
    data
  })
}



